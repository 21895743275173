import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <path
          transform="translate(-3.000000,-4.000000)"
          d="M41.4829 64H30.6548V33.4545H41.5724C44.6448 33.4545 47.2897 34.066 49.5071 35.289C51.7244 36.5021 53.4296 38.2471 54.6228 40.5241C55.826 42.8011 56.4275 45.5256 56.4275 48.6974C56.4275 51.8792 55.826 54.6136 54.6228 56.9006C53.4296 59.1875 51.7144 60.9425 49.4772 62.1655C47.25 63.3885 44.5852 64 41.4829 64ZM37.1129 58.4666H41.2144C43.1235 58.4666 44.7294 58.1285 46.0319 57.4524C47.3444 56.7663 48.3288 55.7074 48.985 54.2756C49.6512 52.8338 49.9843 50.9744 49.9843 48.6974C49.9843 46.4403 49.6512 44.5959 48.985 43.164C48.3288 41.7322 47.3494 40.6783 46.0468 40.0021C44.7443 39.326 43.1385 38.9879 41.2294 38.9879H37.1129V58.4666Z"
          fill="currentColor"
        />

        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
